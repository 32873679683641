<template>
  <app-bar :drawer="drawer" @updateDrawerValue="updateDrawer"></app-bar>
  <v-navigation-drawer v-model="drawer" style="z-index: 99999;">
    <div class="navbar-logo-container">
      <img
        src="../assets/images/logo.png"
        alt=""
        class="navbar-logo"
      />
    </div>
    <v-list density="compact" nav>
      <v-list-item
        prepend-icon="mdi-shape-outline"
        title="Kategoriler"
        value="category"
        @click="navigateTo('/category')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-pill-multiple"
        title="Ürünler"
        value="product"
        @click="navigateTo('/product')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-human-greeting-proximity"
        title="Contact"
        value="contact"
        @click="navigateTo('/contact')"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-format-list-group"
        title="Logs"
        value="log"
        :disabled="true"
        @click="navigateTo('/log')"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import AppBar from './AppBar.vue';
export default {
  name: "navigation-side",
  components: {
    AppBar,
  },
  data: () => ({
    drawer: true,
  }),
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    updateDrawer(value) {
      this.drawer = value;
    },
  },
};
</script>
