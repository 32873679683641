import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignInPage from '../views/auth/sign-in-page.vue'
import store from "@/store";

import childRoutes from "./panel-routes";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
    children: childRoutes
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignInPage,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters["auth/isAuthenticated"];
    if (!isAuthenticated) {
      next({ name: "signin", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router
