// import router from "@/router";
import categoryService from "@/services/categoryService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    categories: [],
    loading: false,
  },
  getters: {
    getCategories: (state) => state.categories,
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initCategories({ commit }, category) {
      try {
        const response = await categoryService.getCategories(category);

        if (response.status === 200) {
          commit("SET_CATEGORIES", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },

    async createCategory({ commit }, category) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: category.name,
          type: category.type,
        };

        const response = await categoryService.createCategory(body);

        return response;
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async getCategoryById(_, categoryId) {
      try {
        const response = await categoryService.getCategoryById(categoryId);
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    async deleteCategory({ commit }, categoryId) {
      try {
        commit("SET_LOADING", true);
        const response = await categoryService.deleteCategory(categoryId);

        if (response.status === 200) {
          commit(
            "SET_CATEGORIES",
            this.state.category.categories.filter(
              (category) => category.id !== categoryId
            )
          );
          notify({
            type: "success",
            text: "Kategori silindi",
          });
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async updateCategory({ commit }, category) {
      try {
        commit("SET_LOADING", true);

        const body = {
          name: category.name,
          type: category.type,
        };

        const response = await categoryService.updateCategory(
          category.id,
          body
        );

        return response;
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async uploadCategoryImage(_, props) {
      try {
        let formData = new FormData();
        for (var i = 0; i < props.images.length; i++) {
          let file = props.images[i];
          formData.append("files[]", file);
        }

        formData.append("imageable_id", props.categoryId);
        formData.append("imageable_type", "category");

        const response = await categoryService.uploadCategoryImage(formData);
        return response;
      } catch (error) {
        return error.response;
      }
    },

    async removeImages(_, props) {
      try {

        const ids = props.map(item => item.id);

        const body = {
          imageIds : ids
        }

        const response = await categoryService.removeCategoryImage(body);
        return response;
      } catch (error) {
        return error.response;
      }
    },

    async updateSortOrder({ commit }, categories) {

      try {
        commit("SET_LOADING", true);

        const body = {
          categories
        }

        const response = await categoryService.updateSortOrder(body);

        if (response.status === 200) {
          notify({
            type: "success",
            text: "Sıralama güncellendi",
          });
        }
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }

    },

    async getCategoriesIdAndName({ commit }, category) {
      try {
        commit('SET_LOADING', true);

        const response = await categoryService.getCategoriesIdAndName(category);
        return response.data;
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    }

  },
};
