export default [
    {
      path: "/category",
      name: "list-category",
      component: () => import("../views/category/list-category-page.vue"),
    },
    {
      path: "/create-category",
      name: "create-category",
      component: () => import("../views/category/create-category-page.vue"),
    },
    {
      path: "/update-category/:id",
      name: "update-category",
      component: () => import("../views/category/update-category-page.vue"),
      props: true
    }
  ];
  