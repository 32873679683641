// import router from "@/router";
import productService from "@/services/productService";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    products: [],
    loading: false,
  },
  getters: {
    getProducts: (state) => state.products,
  },
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    async initProducts({ commit }, categoryId) {
      try {
        const response = await productService.getProducts(categoryId);

        if (response.status === 200) {
          commit("SET_PRODUCTS", response.data);
        }
      } catch (error) {
        return error.response;
      }
    },

    async createProduct({ commit }, formFields) {
      try {
        commit("SET_LOADING", true);

        const body = {
          category_id: formFields.category_id,
          name: formFields.name,
          ingredients: formFields.ingredients,
          how_to_use: formFields.how_to_use,
          short_description: formFields.short_description,
          seo_keywords: formFields.seo_keywords,
          is_active: formFields.is_active,
        };
        
        const response = await productService.createProduct(body);
        return response;
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async getProductById(_, productId) {
      try {
        const response = await productService.getProductById(productId);
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    async deleteProduct({ commit }, productId) {
      try {
        commit("SET_LOADING", true);
        const response = await productService.deleteProduct(productId);

        if (response.status === 200) {
          commit(
            "SET_PRODUCTS",
            this.state.product.products.filter(
              (product) => product.id !== productId
            )
          );
          notify({
            type: "success",
            text: "Ürün silindi",
          });
        }
        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async updateProduct({ commit }, product) {
      try {
        commit("SET_LOADING", true);

        const body = {
          category_id: product.category_id,
          name: product.name,
          ingredients: product.ingredients,
          how_to_use: product.how_to_use,
          short_description: product.short_description,
          seo_keywords: product.seo_keywords,
          is_active: product.is_active,
        };

        const response = await productService.updateProduct(
          product.id,
          body
        );

        return response;
      } catch (error) {
        console.log(error.response.data.error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }
    },

    async uploadProductImage(_, props) {
      try {
        let formData = new FormData();
        for (var i = 0; i < props.images.length; i++) {
          let file = props.images[i];
          formData.append("files[]", file);
        }

        formData.append("imageable_id", props.productId);
        formData.append("imageable_type", "product");

        const response = await productService.uploadProductImage(formData);
        return response;
      } catch (error) {
        return error.response;
      }
    },

    async removeImages(_, props) {
      try {

        const ids = props.map(item => item.id);

        const body = {
          imageIds : ids
        }

        const response = await productService.removeProductImage(body);
        return response;
      } catch (error) {
        return error.response;
      }
    },

    async clearProducts({ commit }) {
      commit("SET_PRODUCTS", []);
    },

    async updateSortOrder({ commit }, products) {

      try {
        commit("SET_LOADING", true);

        const body = {
          products
        }

        const response = await productService.updateSortOrder(body);

        if (response.status === 200) {
          notify({
            type: "success",
            text: "Sıralama güncellendi",
          });
        }
      } catch (error) {
        console.log(error);
        notify({
          type: "error",
          title: "Error",
          text: error.response.data.error,
        });
      }

    },

  },
};
