import categoryRoutes from "./category-routes";
import productRoutes from "./product-routes";
import contactRoutes from "./contact-routes";

const childRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import( "../views/dashboard/dashboard-page.vue"),
  },
  ...categoryRoutes,
  ...productRoutes,
  ...contactRoutes
];

export default childRoutes;
