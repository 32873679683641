// import router from "@/router";
import contactService from "@/services/contactService";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,
    state: {
        contact: {},
        loading: false,
    },
    getters: {
    },
    mutations: {
        SET_LOADING(state, loading) {
            state.loading = loading;
        },
    },
    actions: {
        async initContact() {
            try {
                const response = await contactService.getContact();

                if (response.status != 200) {
                    notify({
                        type: "error",
                        title: "Error",
                        text: response.data.error,
                    });
                }

                return response;
            } catch (error) {
                return error.response;
            }
        },

        async updateContact({ commit }, form) {
            try {
                commit("SET_LOADING", true);

                const body = {
                    phone: form.phone,
                    whatsapp: form.whatsapp,
                    email: form.email,
                    address: form.address,
                    map: form.map,
                    instagram: form.instagram,
                    facebook: form.facebook,
                    twitter: form.twitter,
                    youtube: form.youtube,
                    linkedin: form.linkedin,
                    send_email: form.send_email,
                };

                const response = await contactService.updateContact(body);

                if (response.status === 200) {
                    notify({
                        type: "success",
                        text: "İletişim bilgileri güncellendi",
                    });
                }
            } catch (error) {
                console.log(error.response.data.error);
                notify({
                    type: "error",
                    title: "Error",
                    text: error.response.data.error,
                });
            }
        }
    },
};
