import authService from "@/services/authService";
import router from "../../../router"; // import router from "@/router";
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    user: null,
    updateUserStatus: null,
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    getUser: (state) => state.user,
  },
  mutations: {
    SET_IS_AUTHENTICATED(state, payload) {
      state.isAuthenticated = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    }
  },
  actions: {
    async doSignIn({ commit }, payload) {
      try {
        const data = {
          username: payload.username,
          password: payload.password,
        };

        const response = await authService.login(data);

        if (response.status === "success" && response.token) {
          localStorage.setItem("auth-token", response.token);
          commit("SET_IS_AUTHENTICATED", true);
          commit("SET_USER", response.data);
          router.push({ name: "home" });
        }

        return response;
      } catch (error) {
        notify({
          type: "error",
          title: "Hata",
          text: "Kullanıcı adı veya şifre hatalı",
        });
      }
    },

    async initAuth({ commit }) {
      try {
        const token = localStorage.getItem("auth-token");

        if (!token) {
          return null;
        }

        const response = {
          "name": "Admin",
          "email": "admin@admin.com",
          "username": "admin"
        };

        commit("SET_USER", response);
        commit("SET_IS_AUTHENTICATED", true);

        // Eğer bir redirect yolu varsa, oraya yönlendir
        const redirectPath = router.currentRoute.value.query.redirect || { name: "home" };
        router.push(redirectPath);

        return response;
      } catch (error) {
        return error.response;
      }
    },

    async doSignOut({ commit }) {
      try {

        commit("SET_IS_AUTHENTICATED", false);
        localStorage.removeItem("auth-token");
        router.push({ name: "signin" });

        return true;
      } catch {
        return null;
      }
    },

    async getUserInfos() {
      try {
        const token = localStorage.getItem("auth-token");
        if (!token) {
          return null;
        }
        const response = await authService.verifyUser(token);
        return response;
      } catch (error) {
        console.log("error", error);
      }
    },

    async updateProfileRequest() {

      try {
        const response = await authService.updateProfileRequest();
        return response;
      } catch (error) {
        console.log("error", error);
      }

    },

    async checkVerificationCode(_, code) {
      try {
        const body = {
          code: code,
        };

        const response = await authService.checkVerificationCode(body);
        return response;
      } catch (error) {
        console.log("error", error);
      }
    },

    async updateUserInfos(_, payload) {

      try {

        const body = {
          resetToken: payload.resetToken || null,
          fullname: payload.fullname,
          email: payload.email,
          password: payload.password,
          images: payload.images,
        }

        const response = await authService.updateUserInfos(body);
        return response;

      } catch (error) {
        console.log("error", error);
      }
    },

    async uploadImage(_, image) {
      try {
        let formData = new FormData();
        for (var i = 0; i < image.length; i++) {
          let file = image[i];
          formData.append("images", file);
        }
        const response = await authService.uploadImage(formData);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
