export default [
    {
      path: "/product",
      name: "list-products",
      component: () => import("../views/product/list-product-page.vue"),
    },
    {
      path: "/create-product",
      name: "create-product",
      component: () => import("../views/product/create-product-page.vue"),
    },
    {
      path: "/update-product/:id/:maincategory",
      name: "update-product",
      component: () => import("../views/product/update-product-page.vue"),
      props: true
    }
  ];
  