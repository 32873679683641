import { appAxios } from "./appService";

export default {
  async getProducts(categoryId) {
    const response = await appAxios.get(`/products/${categoryId}`);
    return response.data;
  },
  async createProduct(body) {
    const response = await appAxios.post("/admin/product", body);
    return response.data;
  },
  async deleteProduct(productId) {
    const response = await appAxios.delete(`/admin/product/${productId}`);
    return response.data;
  },
  async updateProduct(productId, body) {
    const response = await appAxios.put(`/admin/product/${productId}`, body);
    return response.data;
  },
  async getProductById(productId) {
    const response = await appAxios.get(`/product/${productId}`);
    return response.data;
  },
  async uploadProductImage(formData) { 
    const response = await appAxios.post("/admin/upload-images", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
  async removeProductImage(body) {
    const response = await appAxios.post(`/admin/remove-images`, body);
    return response.data;
  },
  async updateSortOrder(body) {
    const response = await appAxios.put('/admin/product-sort', body);
    return response.data;
  }
};
