import { appAxios } from "./appService";

export default {
  async getCategories(category) {
    const response = await appAxios.get(`/categories/${category}`);
    return response.data;
  },
  async createCategory(body) {
    const response = await appAxios.post("/admin/category", body);
    return response.data;
  },
  async deleteCategory(categoryId) {
    const response = await appAxios.delete(`/admin/category/${categoryId}`);
    return response.data;
  },
  async updateCategory(categoryId, body) {
    const response = await appAxios.put(`/admin/category/${categoryId}`, body);
    return response.data;
  },
  async getCategoryById(categoryId) {
    const response = await appAxios.get(`/category/${categoryId}`);
    return response.data;
  },
  async uploadCategoryImage(formData) { 
    const response = await appAxios.post("/admin/upload-images", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
  async removeCategoryImage(body) {
    const response = await appAxios.post(`/admin/remove-images`, body);
    return response.data;
  },
  async updateSortOrder(body) {
    const response = await appAxios.put('/admin/category-sort', body);
    return response.data;
  },
  async getCategoriesIdAndName(category) {
    const response = await appAxios.get(`/admin/category-id-name/${category}`);
    return response.data;
  }
};
