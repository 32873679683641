import { createStore } from 'vuex'
import authModule from './modules/auth'
import categoryModule from './modules/category'
import productModule from './modules/product'
import contactModule from './modules/contact'

export default createStore({
  modules: {
    auth: authModule,
    category: categoryModule,
    product: productModule,
    contact: contactModule,
  }
})
