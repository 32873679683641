import { appAxios } from "./appService";

export default {
  async getContact() {
    const response = await appAxios.get(`/contact`);
    return response.data;
  },
  async updateContact(body) {
    const response = await appAxios.put(`/admin/contact`, body);
    return response.data;
  }
};
